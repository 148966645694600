import {
    Badge, Body1Stronger, Button, Dialog, DialogActions, DialogBody, DialogContent,
    DialogSurface, DialogTitle, FluentProvider, Link, ProgressBar, Toaster, useId
} from "@fluentui/react-components";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { Login, Providers, ThemeToggle } from "@microsoft/mgt-react";
import { lazy, ReactElement, useEffect, useState, useTransition } from "react";
import { useIdleTimer } from "react-idle-timer";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { JTool } from "./Components/JTool";
import JToolContext from "./Components/JToolContext";
import Nav from "./Components/Nav";
import useIsSignedIn from "./Hooks/useIsSignedIn";
import useTheme from "./Hooks/useTheme";
import { LogType } from "./Interfaces";
import HomePage from "./Pages/HomePage";
import logo from "./logo.svg";

const Tenants = lazy(() => import("./Pages/Automation"));
const Logs = lazy(() => import("./Pages/LogsPage"));
const HealthCheck = lazy(() => import("./Pages/HealthCheckPage"));
const Reports = lazy(() => import("./Pages/ReportsPage"));
const Permissions = lazy(() => import("./Pages/PermissionsPage"));
const About = lazy(() => import("./Pages/AboutPage"));
const Database = lazy(() => import("./Pages/DatabasePage"));

export interface IAppProps { appInsights?: ApplicationInsights }

function App(props: IAppProps): ReactElement {
    const timeout = 1800_000;
    const promptBeforeIdle = 30_000;
    const [isPending, startTransition] = useTransition();
    const toasterId = useId("toaster");
    const [isDark, theme] = useTheme();
    const { isSignedIn, isAdminTenant, tenant, loading, token } = useIsSignedIn(true, props.appInsights);
    const navigator = useNavigate();
    const [collapseNav, setCollapseNav] = useState<boolean>(false);
    const [idleOpen, setIdleOpen] = useState<boolean>(false);
    const [remaining, setRemaining] = useState<number>(timeout);

    const onIdle = () => {
        if (isSignedIn && Providers.globalProvider.logout) Providers.globalProvider.logout();
        if (idleOpen) setIdleOpen(false);
    };

    const onActive = () => {
        if (idleOpen) setIdleOpen(false);
    };

    const onPrompt = () => {
        setIdleOpen(isSignedIn);
    };

    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 500
    });

    useEffect(() => {
        let interval: NodeJS.Timeout | undefined = undefined;
        if (idleOpen) interval = setInterval(() => setRemaining(Math.ceil(getRemainingTime() / 1000)), 500);
        return () => clearInterval(interval);
    });

    return (
        <FluentProvider theme={theme}>
            <JToolContext.Provider value={new JTool(tenant, Providers.client, token, toasterId)}>
                <div id="jtool" className={`App ${isDark ? "mgt-dark" : ""}${collapseNav ? " collapseNav" : ""}`}>
                    <div style={{ display: "none", visibility: "hidden" }}><ThemeToggle darkModeActive={isDark} /></div>
                    <header id="header">
                        <Link href="/" onClick={(e) => { e.preventDefault(); startTransition(() => navigator("/")); }} appearance='subtle' className="App-logo">
                            <img src={logo} alt="logo" />
                            <Body1Stronger>Cloud Assist</Body1Stronger>
                            <Badge appearance='tint' size='large' color='warning'>ALPHA</Badge>
                        </Link>
                        {isSignedIn && <Login />}
                    </header>
                    <Nav collapseNav={collapseNav} onCollapseNav={setCollapseNav} />
                    <div id="content">
                        {(loading || isPending) && <ProgressBar />}
                        {<Routes>
                            <Route path="*" element={<HomePage loading={loading} />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/permissions" element={<Permissions />} />
                            {isSignedIn && <>
                                {(tenant?.hasCe || isAdminTenant) && (tenant?.isPaidUp || tenant?.isPreview || tenant?.isReadonly) && <>
                                    <Route path="/automations" element={<Tenants />} />
                                    <Route path="/automations/logs" element={<Logs Mode={LogType.Tenant} />} />
                                    <Route path="/database/*" element={<Database />} />
                                    <Route path="/reports/*" element={<Reports />} />
                                </>}
                                <Route path="/health/*" element={<HealthCheck />} />
                            </>}
                        </Routes>}
                    </div>
                </div>
                <Dialog open={idleOpen} onOpenChange={(_, d) => setIdleOpen(d.open)}>
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>Are you still here?</DialogTitle>
                            <DialogContent>
                                Logging out in {remaining}
                            </DialogContent>
                            <DialogActions>
                                <Button appearance="primary" onClick={activate}>I'm here</Button>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
                <Toaster toasterId={toasterId} />
            </JToolContext.Provider>
        </FluentProvider>
    );
}

export default App;