import { AndroidPolicy, DeadlinePolicy, Policy, WindowsPolicy } from "../service/JiscM365CsModelsModel";

export enum EPolicyBaseType {
    "Policy" = "#Jisc.M365CS.Models.TenantModels.Policy",
    "WindowsPolicy" = "Jisc.M365CS.Models.TenantModels.WindowsPolicy",
    "AndroidPolicy" = "#Jisc.M365CS.Models.TenantModels.AndroidPolicy",
    "DeadlinePolicy" = "#Jisc.M365CS.Models.TenantModels.DeadlinePolicy"
}

export type EPolicy = (Policy & {
    "@odata.type": EPolicyBaseType;
})

export type EWindowsPolicy = (WindowsPolicy & {
    "@odata.type": EPolicyBaseType;
})

export type EAndroidPolicy = (AndroidPolicy & {
    "@odata.type": EPolicyBaseType;
})

export type EDeadlinePolicy = (DeadlinePolicy & {
    "@odata.type"?: EPolicyBaseType;
})