import { Providers } from "@microsoft/mgt-element";
import { Client } from "@microsoft/microsoft-graph-client";
import { FetchClient } from "@odata2ts/http-client-fetch";
import { Tenant } from "../service/JiscM365CsModelsModel";
import { JiscM365CsModelsService } from "../service/JiscM365CsModelsService";

export class JTool {
    readonly tenant?: Tenant;
    readonly client?: Client;
    private readonly reqInit?: HeadersInit;
    jservice?: JiscM365CsModelsService<FetchClient>;
    constructor(Tenant?: Tenant, Client?: Client, Bearer?: string, ToasterId?: string) {
        this.tenant = Tenant;
        this.client = Client;
        this.toasterId = ToasterId;
        if (Bearer) {
            this.reqInit = { "Authorization": `Bearer ${Bearer}` };
            this.jservice = new JiscM365CsModelsService(new FetchClient({ "headers": this.reqInit }), `https://${Providers.globalProvider.customHosts!.at(0)}/v1.0`);
        }
    }
    toasterId?: string;
    jfetch = (path: string) => fetch(path, { "headers": this.reqInit });
    makePath = (path: string, noV1 = false): string => path.startsWith("http") ? path : (`https://${Providers.globalProvider.customHosts!.at(0)}${noV1 ? "" : "/v1.0"}${path.startsWith("/") ? path : `/${path}`}`);
}