import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import { useCallback, useEffect, useState } from "react";
import { JTool } from "../Components/JTool";
import { IisSignedIn, IJWT, TenantRole } from "../Interfaces";
import { Tenant } from "../service/JiscM365CsModelsModel";

const useIsSignedIn = (loadTenant?: boolean, appInsights?: ApplicationInsights): IisSignedIn => {
    const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
    const [isAdminTenant, setAdminTenant] = useState<boolean>(false);
    const [tenant, setTenant] = useState<Tenant>();
    const [loading, setLoading] = useState<boolean>(false);
    const [role, setRole] = useState<TenantRole>(TenantRole.Readonly);
    const [token, setToken] = useState<string>();
    const [tid, setTid] = useState<string>();

    const handleSignIn = useCallback((_token: string) => {
        const base64Url = _token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(window.atob(base64).split("").map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(""));

        const jwt: IJWT = JSON.parse(jsonPayload);
        setAdminTenant(jwt.tid === "48f9394d-8a14-4d27-82a6-f35f12361205");
        setRole(jwt.roles?.includes("Admin") ? TenantRole.Admin : TenantRole.Readonly);
        setTid(jwt.tid);

        if (loadTenant === true) {
            setToken(_token);
            if (appInsights) appInsights.setAuthenticatedUserContext(jwt.upn, jwt.upn, true);
            setLoading(true);
            new JTool(undefined, Providers.client, _token, undefined).jservice?.tenants(jwt.tid).query()
            .then((v) => setTenant(v.data))
            .finally(() => setLoading(false))
            .catch(() => {
                setTenant(undefined);
                setRole(TenantRole.Readonly);
            });
        }
    }, [loadTenant, appInsights]);

    useEffect(() => {
        const updateState = () => {
            const provider = Providers.globalProvider;
            setIsSignedIn(provider && provider.state === ProviderState.SignedIn);
            if (provider.state === ProviderState.SignedIn) Providers.globalProvider.getAccessTokenForScopes("api://820855a8-604e-45e3-842c-73081b0396cc/user").then(handleSignIn);
            else {
                if (appInsights) appInsights.clearAuthenticatedUserContext();
                setLoading(false);
                setAdminTenant(false);
                setTenant(undefined);
                setRole(TenantRole.Readonly);
            }
        };

        Providers.onProviderUpdated(updateState);
        updateState();

        return () => {
            Providers.removeProviderUpdatedListener(updateState);
        };
    }, [handleSignIn, loadTenant, appInsights]);

    return { isSignedIn, isAdminTenant, tenant, loading, role, token, tid };
};

export default useIsSignedIn;